/**
 * 公共业务服务
 */
import q from 'q';

import httpHelper from '@/utils/request';
import { common } from '@/common/urls';

const { GetMerchantInfoUrl, GetMemberInfoUrl, JudgeIsMemberUrl } = common;
/**
 * 获取商户信息
 * @param {*} merchantid 商户ID
 */
const GetMerchantInfo = function(merchantid) {
  const deferred = q.defer();
  const url = `${GetMerchantInfoUrl}/${merchantid}`;
  httpHelper
    .get(url, {}, {noLoading: true})
    .then(res => {
      deferred.resolve(res);
    })
    .catch(err => {
      deferred.reject(err);
    });
  return deferred.promise;
};

/**
 * 获取会员信息
 */
const GetMemberInfo = () => httpHelper.get(GetMemberInfoUrl, {}, {noLoading: true});

/**
 * 判断是否是会员
 * @param {*} data code 微信授权code 如果登录则传空字符串 merchantId 商户id
 */
const JudgeIsMember = data => httpHelper.get(JudgeIsMemberUrl, data);

export default {
  GetMerchantInfo,
  GetMemberInfo,
  JudgeIsMember,
};
