import Login from '@/login/login.route'
import Home from '@/home/home.route'
import MemberCard from '@/member_card/member_card.route'
import OilCard from '@/oil_card/oil_card.route'
import MyInfo from '@/my_info/my_info.route'
import StoredValue from '@/stored_value/stored_value.route'
import PointMall from '@/point_mall/point_mall.route'
import Coupon from '@/coupon/coupon.route'
import Payment from '@/payment/payment.route'
import Share from '@/share/share.route'
import CurrentOilPrice from '@/current_oil_price/current_oil_price.route'

export default {
  ...Login.modules,
  ...Home.modules,
  ...MemberCard.modules,
  ...OilCard.modules,
  ...MyInfo.modules,
  ...StoredValue.modules,
  ...PointMall.modules,
  ...Coupon.modules,
  ...Payment.modules,
  ...Share.modules,
  ...CurrentOilPrice.modules
}