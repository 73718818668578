/**
 * 首页路由
 */
import Loadable from 'react-loadable';
import {Loading} from '@/common/components/widget';

/**
 * 模块
 */

const CurrentOilPrice = Loadable({
    loader: () => import('./index.jsx'),
    loading: Loading
})

// 路由配置
const routes = [
    {path: '/app/current-oil-price', component: 'CurrentOilPrice', title: '今日油价1'},
    {path: '/app/current-oil-price/:merchantid', component: 'CurrentOilPrice', title: '今日油价'},
]

const modules = {
    CurrentOilPrice
}

export default {
    routes,
    modules
};