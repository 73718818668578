/**
 * 公共业务请求接口Url
 */
// 获取商户信息url
const GetMerchantInfoUrl = '/h5/mbr/merchant/get';

// 获取会员信息
const GetMemberInfoUrl = '/h5/mbr/member/get-detail';

// 判断是否是会员
const JudgeIsMemberUrl = '/h5/order/payment/mbr-info';

export default {
  GetMerchantInfoUrl,
  GetMemberInfoUrl,
  JudgeIsMemberUrl,
};
