/**
 * 卡券服务Url
 */

// 获取卡券广场Url
const GetCouponSquareListUrl = '/h5/coupon/find-list';

// 卡券广场卡券详情Url
const GetCouponDetailUrl = '/h5/coupon/get-detail';

// 我的卡券详情Url
const GetMyCouponDetailUrl = '/h5/my-coupon/get-detail/';

// 查找卡券详情（用于推广二维码）
const findCouponUrl = '/h5/mbr/coupon/get-promote-coupon/';

// 领取卡券
const TakeCouponUrl = '/h5/coupon/receive';

// 获取用户卡券
const GetMemberCouponsUrl = '/h5/my-coupon/find-list';

export default {
  GetCouponSquareListUrl,
  GetCouponDetailUrl,
  findCouponUrl,
  TakeCouponUrl,
  GetMemberCouponsUrl,
  GetMyCouponDetailUrl,
};
