/**
 * 积分商城URL
 */

// 积分商城列表（分页）
const GetMallListUrl = '/h5/score/score-exchange/query-score-exchange-list';

// 获取商品详情
const GetGoodsDetailUrl = '/h5/score/score-exchange/get';

// 兑换商品
const RedeemGoodsUrl = '/h5/score/score-exchange/receive';

// 获取兑换记录
const GetGoodsRedeemRecordUrl = '/h5/score/score-exchange-record/get';

export default {
  GetMallListUrl,
  GetGoodsDetailUrl,
  RedeemGoodsUrl,
  GetGoodsRedeemRecordUrl,
};
