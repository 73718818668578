/**
 * 油卡路由
 */
import Loadable from 'react-loadable';
import {Loading} from '@/common/components/widget';

// 我的油卡
const OilDetail = Loadable({ 
  loader: () => import('./containers/detail/OilDetail.jsx'),
  loading: Loading
})

// 路由配置
const routes = [
  {path: '/app/member/detail', component: 'OilDetail', title: '我的油卡'}
]

const modules = {
  OilDetail
}

export default {
  routes,
  modules
};