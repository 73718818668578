import axios from 'axios';
import { Toast } from 'antd-mobile';
import { get as getCookie } from '../utils/cookie';
import store from '@/redux/storeInstance';
import {changeLoadingStatus} from '@/redux/actions';

const request = axios.create({
  timeout: 500000,
});

request.interceptors.request.use(
  config => {
    if (localStorage.getItem('user-token')) {
      config.headers.token = localStorage.getItem('user-token');
    }
    return config;
  },
  error => {
    Toast.fail(error.message);
    Promise.reject(error);
  }
);

request.interceptors.response.use(
  response => {
    if (response.data.errCode === '000006') {
      const { pathname } = window.location;
      let merchantinfo = localStorage.getItem('merchantinfo');

      merchantinfo = merchantinfo ? JSON.parse(merchantinfo) : {};
      if (pathname.indexOf('/app/login') === -1) {
        window.location.href = `${window.location.origin}/h5/mbr/user/entry/${merchantinfo.id}?redirectPath=${pathname}`;
      }
    }
    return response;
  },
  error => {
    Toast.fail(error.message);
    return Promise.reject(error);
  }
);

const get = (url, params = {}, headers = {}) => {
  if(!headers.noLoading) {
    store.dispatch(changeLoadingStatus(true))
  }

  return request({
    url,
    method: 'get',
    params,
    headers
  }).then(res => {
    if(!headers.noLoading) {
      store.dispatch(changeLoadingStatus(false))
    }
    if (res.data && res.data.success) {
      return Promise.resolve(res.data.data);
    } else {
      const errmsg = res.data.errMsg || '请求异常，请稍后再试!';
      if (errmsg.indexOf('未登录') < 0){
        Toast.fail(errmsg);
      }
      return Promise.reject(errmsg);
    }
  }, () => {
    if(!headers.noLoading) {
      store.dispatch(changeLoadingStatus(false))
    }
  });
}

const post = (url, data = {}, headers = {}) => {
  if(!headers.noLoading) {
    store.dispatch(changeLoadingStatus(true))
  }
  return request({
    url,
    method: 'post',
    data,
    headers
  }).then((res) => {
    if(!headers.noLoading) {
      store.dispatch(changeLoadingStatus(false))
    }
    if (res.data && res.data.success) {
      return Promise.resolve(res.data.data);
    } else {
      const errMsg = res.data.errMsg || '请求异常，请稍后再试!';
      if (errMsg.indexOf('未登录') < 0){
        Toast.fail(errMsg);
      }
      return Promise.reject(errMsg);
    }
  }, () => {
    if(!headers.noLoading) {
      store.dispatch(changeLoadingStatus(false))
    }
  })
}

const getWithres =  (url, params = {}, headers = {}) => {
  if(!headers.noLoading) {
    store.dispatch(changeLoadingStatus(true))
  }
  return request({
    url,
    method: 'get',
    params,
    headers
  }).then((res) => {
    if(!headers.noLoading) {
      store.dispatch(changeLoadingStatus(false))
    }
    return Promise.resolve(res.data);
  }, () => {
    if(!headers.noLoading) {
      store.dispatch(changeLoadingStatus(false))
    }
  })
}

const postWithRes = (url, data = {}, headers = {}) => {
  if(!headers.noLoading) {
    store.dispatch(changeLoadingStatus(true))
  }
  return request({
    url,
    method: 'post',
    data,
    headers
  }).then((res) => {
    if(!headers.noLoading) {
      store.dispatch(changeLoadingStatus(false))
    }
    return Promise.resolve(res.data);
  }, () => {
    if(!headers.noLoading) {
      store.dispatch(changeLoadingStatus(false))
    }
  })
}

export default {
  get,
  post,
  getWithres,
  postWithRes
}