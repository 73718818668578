/**
 * 全局Loading组件
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './loading.less';

class Loading extends Component {
  static propTypes = {
    visible: PropTypes.bool
  };

  static defaultProps = {
    visible: false
  };
  render() {
    const {visible} = this.props;
    return (
      <div className="g-loading" style={{display: visible ? 'block' : 'none'}}>
        <div className="g-loading-content">
          <svg viewBox="25 25 50 50" className="circular">
            <circle cx="50" cy="50" r="20" fill="none" className="path"></circle>
          </svg>
        </div>
      </div>
    )
  }
}

export default Loading;
