import React from 'react';
import {connect} from 'react-redux';
import NotFoundComponent from '../common/components/404/NotFound';
import loginService from '../login/services/login.service';

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tipInfo: '未找到相应页面，点击返回会员中心',
            btnInfo: '进入会员中心'
        }
    }

    goHome = () => {
        // 判断用户登录状态
        let merchantid = 0;
        const {params, MerchantInfo, history} = this.props;
        if (params && params.merchantid) {
            merchantid = params.merchantid;
        } else {
            if (MerchantInfo != null) {
                merchantid = MerchantInfo.id;
            } else {
                const merchantinfo_store = localStorage.getItem('merchantinfo');
                if (merchantinfo_store) {
                    const merchantinfo_obj = JSON.parse(merchantinfo_store);
                    merchantid = merchantinfo_obj.id
                }
            }
        }
        loginService.JudgeUserLoginStatus(merchantid).then(res => {
            if (res != null) {
                if (res.logined) {
                    history.replace('/app/home');
                } else {
                    window.location.href = `${window.location.origin}/h5/mbr/user/entry/${merchantid}`;
                }
            } else {
                window.location.href = `${window.location.origin}/h5/mbr/user/entry/${merchantid}`;
            }
        })
    }

    render() {
        const {tipInfo, btnInfo} = this.state;
        return (
            <NotFoundComponent tipTextGet={tipInfo} btnTextGet={btnInfo} btnClickGet={this.goHome}/>
        )
    }
}

export default connect(state => ({
    MerchantInfo: state.MerchantInfo
}),{})(NotFound)