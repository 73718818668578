import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import NotFound from '@/not_found/NotFound';
import Error from '@/common/components/error/Error';
import PayError from '@/common/components/error/PayError';
import App from '@/App';

export default () => (
  <Switch>
    <Route exact path="/" render={() => <Redirect to="/app/home" push />} />        
    <Route path="/app" component={App} />
    <Route path="/app/error" component={Error} />
    <Route path="/app/pay/error" component={PayError} />
    <Route path="/app/404" component={NotFound} />
    <Route component={NotFound} />
  </Switch>
)