/**
 * 首页路由
 */
import Loadable from 'react-loadable';
import { Loading } from '@/common/components/widget';

/**
 * 模块
 */

const Home = Loadable({
  loader: () => import('./index.jsx'),
  loading: Loading,
});

const RightCenter = Loadable({
  loader: () => import('./container/rightCenter'),
  loading: Loading,
});

const TodoModule = Loadable({
  loader: () => import('./container/TodoModule'),
  loading: Loading,
});

// 路由配置
const routes = [
  { path: '/app/home', component: 'Home', title: '会员中心' },
  { path: '/app/home/:merchantid', component: 'Home', title: '会员中心' },
  { path: '/app/rightCenter', component: 'RightCenter', title: '权益中心' },
  { path: '/app/todoModule', component: 'TodoModule', title: '等待开发'  },
];

const modules = {
  Home,
  RightCenter,
  TodoModule,
};

export default {
  routes,
  modules,
};
