/**
 * 首页请求服务Url
 */

// 首页数据请求url
const homeDataUrl = '/h5/mbr/member/get-detail';
// 获取轮播通知
const GetNoticeUrl = '/h5/mbr/notice/find-notice';
const GetMessageUrl = '/h5/mbr/msg/get';
const GetRightCenterInfoUrl = '/h5/mbr/benefit/get';

export default {
    homeDataUrl,
    GetNoticeUrl,
    GetMessageUrl,
    GetRightCenterInfoUrl
}