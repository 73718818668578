import {combineReducers} from 'redux';
import * as type from './action-types'
import initState from './init_state'

import LoginReducers from '@/login/redux/reducers';
import HomeReducers from '@/home/redux/reducers';
import MemberCardReducers from '@/member_card/redux/reducers';
import OilCardReducers from '@/oil_card/oil_card.reducer';
import MyInfoReducers from '@/my_info/redux/reducers';
import StoredValueReducers from '@/stored_value/stored_value.reducer';
import PointMallReducers from '@/point_mall/point_mall.reducer';
import CouponReducers from '@/coupon/coupon.reducer';
import PaymentReducers from '@/payment/payment.reducer';
import ShareReducers from '@/share/share.reducer';

/**
 * 公共reducer
 */

const handleData = (state = {isFetching: true, data: {}}, action) => {
  switch (action.type) {
    case type.REQUEST_DATA:
      return {...state, isFetching: true};
    case type.RECEIVE_DATA:
      return {...state, isFetching: false, data: action.data};
    default:
      return {...state};
  }
};

/**
 * APP全局状态
 * ../..param {*} state 
 * ../..param {*} action 
 */
const AppData = (state = initState.AppGlobalState, action) => {
  switch (action.type) {
    case type.RECEIVE_DATA:
    case type.REQUEST_DATA:
      return {
        ...state,
        [action.category]: handleData(state[action.category], action)
      };
    case type.LOADING:
      return {
        ...state,
        loading: action.data
      };
    default:
      return {...state};
  }
};

/**
 * 商户信息
 * ../..param {*} state 
 * ../..param {*} action 
 */
const MerchantInfo = (state = null, action) => {
  switch (action.type) {
    case type.GET_MERCHANTINFO:
      return {...state, ...action.data};
    default:
      return state;
  }
}

/**
 * 用户登录信息
 * ../..param {*} state 
 * ../..param {*} action 
 */
const UserLoginInfo = (state = null, action) => {
  switch (action.type) {
    case type.GET_USERLOGININFO:
      return {...state, ...action.data};
    default:
      return state;
  }
}

/**
 * 获取会员信息
 * ../..param {*} state 
 * ../..param {*} action 
 */
const MemberInfo = (state = null, action) => {
  switch (action.type) {
    case type.GET_MEMBERINFO:
      return {...state, ...action.data};
    default:
      return state;
  }
}

// 多函数组合
export default combineReducers({
  AppData,
  MerchantInfo,
  UserLoginInfo,
  MemberInfo,
  ...LoginReducers,
  ...HomeReducers,
  ...MemberCardReducers,
  ...OilCardReducers,
  ...MyInfoReducers,
  ...StoredValueReducers,
  ...PointMallReducers,
  ...CouponReducers,
  ...PaymentReducers,
  ...ShareReducers
})

