/**
 * 支付服务Url
 */

// 获取适用的sku数据
const GetSkuDataUrl = '/h5/order/payment/gas/find-skus';

// 支付
const ToPayUrl = '/h5/order/payment/gas/ctb-pay';

// 支付后优惠券
const GetAfterPayCouponUrl = '/h5/order/payment/get-pay-gift';

// 关注微信公众号
const GetWXMerchantUrl = '/h5/wx/open-mp/get-qrcode-link';

// 获取用户信息
const GetMerchantInfoUrl = '/h5/order/payment/gas/mer-info';

// 获取付款码
const GetPayCodeUrl = '/h5/mbr/member/get-pay-code';

export default {
  GetSkuDataUrl,
  ToPayUrl,
  GetAfterPayCouponUrl,
  GetWXMerchantUrl,
  GetMerchantInfoUrl,
  GetPayCodeUrl,
  getFuelMenListUrl: '/h5/mbr/staff/find-onduty-list',
};
