import React, {Component} from 'react';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import {receiveData, GetMerchantInfoAction, GetMemberInfoAction} from '@/redux/actions';
import Router from '@/router/router';
import * as Sentry from '@sentry/browser';
import Loading from '@/common/components/loading/Loading';
import '@/common/style/index.less';
import '@/common/style/antd-mobile/index.less';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ''
    };
  }

  componentDidMount() {
    this.bindMerchantInfo();
    const pathname = this.props.history.location.pathname || '';
    if(pathname !== '/app/payment' && pathname.indexOf('/app/current-oil-price')===-1) {
      this.props.GetMemberInfoAction().then(res => {
        if (process.env.NODE_ENV === 'production') {
          Sentry.init({dsn: "http://f50c1a08f3274a69933b550ef0e63b99@sentry.chuangjiangx.com/9"});
          Sentry.setUser(res || {});
        }
      });
    }
  }
  
  bindMerchantInfo = () => {
    const {GetMerchantInfoAction} = this.props;
    const query = queryString.parse(window.location.search);
    if (query && query.merchantId) {
      GetMerchantInfoAction(query.merchantId);
    } else {
      const merchantinfo_store = localStorage.getItem('merchantinfo');
      if (merchantinfo_store) {
        const merchantinfo_obj = JSON.parse(merchantinfo_store);
        GetMerchantInfoAction(merchantinfo_obj.id);
      }
    }
  }    

  render() {
    const { title, loading } = this.props;
    let docuTitle = title && title.data && JSON.stringify(title.data) != '{}' ? title.data : this.state.title;
    return (
      <DocumentTitle title={docuTitle}>
        <div>
          <Loading visible={loading} />
          <Router />
        </div>
      </DocumentTitle>
    );
  }
}


export default withRouter(connect(state => {
  const {loading, title} = state.AppData;
  return {
    title,
    MerchantInfo: state.MerchantInfo,
    UserLoginInfo: state.UserLoginInfo,
    MemberInfo: state.MemberInfo,
    loading
  }
}, {receiveData, GetMerchantInfoAction, GetMemberInfoAction})(App));
