/**
 * 卡券路由
 */
import Loadable from 'react-loadable';
import {Loading} from '@/common/components/widget';

/**
 * 模块
 */

// 卡券广场
const CouponList = Loadable({
  loader: () => import('./containers/coupon_square/List.jsx'),
  loading: Loading
});

// 卡券详情
const CouponDetail = Loadable({
  loader: () => import('./containers/coupon_square/Detail.jsx'),
  loading: Loading
});

// 我的卡包
const CouponPackage = Loadable({
  loader: () => import('./containers/coupon_package/CouponPackage.jsx'),
  loading: Loading
});

// 兑换券详情
const ExchangeCardDetail = Loadable({
  loader: () => import('./containers/exchangeCardDetail'),
  loading: Loading
});

// 路由
const routes = [
  {path: '/app/coupon/list', component: 'CouponList', title: '卡券广场'},
  {path: '/app/coupon/list/:merchantid', component: 'CouponList', title: '卡券广场'},
  {path: '/app/coupon/detail', component: 'CouponDetail', title: '卡券详情'},
  {path: '/app/coupon/coupon_package', component: 'CouponPackage', title: '我的卡包'},
  {path: '/app/coupon/exchangeCardDetail/:id', component: 'ExchangeCardDetail', title: '兑换券详情'},
];

const modules = {
  CouponList,
  CouponDetail,
  CouponPackage,
  ExchangeCardDetail
};

export default {
  routes,
  modules
};