import Loadable from 'react-loadable';
import { Loading } from '@/common/components/widget';

const Payment = Loadable({
    loader: () => import('./containers/payment/Payment.jsx'),
    loading: Loading
});

const PaySuccess = Loadable({
  loader: () => import('./components/pay_success/PaySuccess.jsx'),
  loading: Loading
});

const PaymentCode = Loadable({
  loader: () => import('./containers/code/PaymentCode.jsx'),
  loading: Loading,
});

// 路由
const routes = [
  { path: '/app/payment', component: 'Payment' },
  { path: '/app/payment/code', component: 'PaymentCode', title: '付款码' },
  { path: '/app/payment/pay_success', component: 'PaySuccess', title: '付款结果' },
];

const modules = {
  Payment,
  PaySuccess,
  PaymentCode,
};

export default {
  routes,
  modules,
};
