/**
 * 我的油卡Service-URL
 */

// 获取每个卡种的信息（余额）以及相关的SKU
const GetCardDetailUrl = '/h5/mbr/my-card-spec/find-mbr-card/info';

// 获取单个卡种下的目录商品
const GetCardSpecProsUrl = '/h5/mbr/my-card-spec/card-spec';

// 获取用户所拥有的卡种
const findMbrHasCardUrl = '/h5/mbr/my-card-spec/find/mbr-has-card';

// 获取每个卡片的消费统计
const GetCardDetailAmountUrl = '/h5/mbr/my-card-spec/find-count-info';

// 油卡页面统一一个接口
const GetMyOilsCardInfoUrl = '/h5/mbr/my-card-spec/card-myoil';

export default {
  GetCardDetailUrl,
  GetCardSpecProsUrl,
  findMbrHasCardUrl,
  GetCardDetailAmountUrl,
  GetMyOilsCardInfoUrl,
};
