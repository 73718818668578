import couponUrls from '@/coupon/services/coupon.url';
import currentOilPriceUrls from '@/current_oil_price/services/current_oil_price.url';
import homeUrls from '@/home/services/home.url';
import loginUrls from '@/login/services/login.url';
import memberCardUrls from '@/member_card/services/member_card.url';
import myInfoUrls from '@/my_info/services/my_info.url';
import oilCardActivationUrls from '@/oil_card/services/activation/activation.url';
import oilCardDetailUrls from '@/oil_card/services/detail/detail.url';
import paymentUrls from '@/payment/services/payment.url';
import pointMallDeliveryCodeUrls from '@/point_mall/services/delivery_code/delivery_code.url';
import pointMallMallUrls from '@/point_mall/services/mall/mall.url';
import pointMallPointDetailUrls from '@/point_mall/services/point_detail/point_detail.url';
import storedValueUrls from '@/stored_value/services/recharge.url';
import commonBusinessUrls from '@/common/services/business/business.url';
import commonCommonUrls from '@/common/services/common/common.url';

import envConfig from '@/envConfig';

const common = {
  ...commonBusinessUrls,
  ...commonCommonUrls,
};
const coupon = {
  ...couponUrls,
};
const currentOilPrice = {
  ...currentOilPriceUrls,
};
const home = {
  ...homeUrls,
};
const login = {
  ...loginUrls,
};
const memberCard = {
  ...memberCardUrls,
};
const myInfo = {
  ...myInfoUrls,
};
const oilCard = {
  ...oilCardActivationUrls,
  ...oilCardDetailUrls,
};
const payment = {
  ...paymentUrls,
};
const pointMall = {
  ...pointMallDeliveryCodeUrls,
  ...pointMallMallUrls,
  ...pointMallPointDetailUrls,
};
const storedValue = {
  ...storedValueUrls,
};

const urls = {
  common,
  coupon,
  currentOilPrice,
  home,
  login,
  memberCard,
  myInfo,
  oilCard,
  pointMall,
  payment,
  storedValue,
};
if (process.env.NODE_ENV === 'development') {
  (() => {
    const { env, mockUrls } = envConfig;
    const crtConfig = mockUrls.find(i => i.key === env);

    Object.keys(urls).forEach(u => {
      const tempObj = urls[u];
      Object.keys(tempObj).forEach(key => {
        // 如果在simulate模式下并且存在mockUrl并且当前name和mockUrl中不匹配的情况下，直接赋值dev环境，其他的全部按当前环境修改url
        if (env === 'mock' && !!crtConfig && crtConfig.mockUrl.length > 0 && !crtConfig.mockUrl.includes(key)) {
          tempObj[key] = `/dev${tempObj[key]}`;
        } else {
          tempObj[key] = `/${env}${tempObj[key]}`;
        }
      });
    });
  })();
}
export { common, coupon, currentOilPrice, home, login, memberCard, myInfo, oilCard, pointMall, payment, storedValue };
