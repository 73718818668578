/**
 * 登录路由
 */
import Loadable from 'react-loadable';
import {Loading} from '../common/components/widget';

/**
 * 模块
 */

const Login = Loadable({ 
    loader: () => import('./index.jsx'),
    loading: Loading
})

// 路由配置
const routes = [
    {path: '/app/login', component: 'Login'},
    {path: '/app/login/:merchantid', component: 'Login'}
]

const modules = {
    Login
}

export default {
    routes,
    modules
};