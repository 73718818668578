import React from 'react';
import LoadableError from './assets/images/loadableError.png';
import "./loading.less";

export default props => {
  if(props.error) {
    // window.location.replace(window.location.href);
    // return;
    return (
      <div className="loadable-error">
        <img className="img-error" src={LoadableError} alt="" />
        <div className="title">页面跑丢了</div>
        <div className="error">{JSON.stringify(props.error)}</div>
        <button className="btn-retry" onClick={props.retry}>重试</button>
      </div>
    )
  } else {
    return (
      <p className="loading-container">
        <svg viewBox="25 25 50 50" className="circular">
          <circle cx="50" cy="50" r="20" fill="none" className="path"></circle>
        </svg>
      </p>
    )
  }
}
