/**
 * 个人信息URL
 */
// 获取个人信息
const GetPersonalInfoUrl = '/h5/mbr/personal/get-base-info';

// 更新个人信息
const UpdatePersonalInfoUrl = '/h5/mbr/personal/modify-base-info';

const GetWxcardUrl = '/h5/mbr/personal/receive-wxcard';

export default {
  GetPersonalInfoUrl,
  UpdatePersonalInfoUrl,
  GetWxcardUrl,
};
