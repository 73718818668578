/**
 * 我的提货码服务Url
 */

// 获取提货码列表
const GetDeliveryCodeListUrl = '/h5/score/score-exchange-record/find-score-exchange-record-list';

export default {
  GetDeliveryCodeListUrl,
};
