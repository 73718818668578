/**
 * 积分明细服务Url
 */

// 获取积分股则以及积分须知
const GetPointInformationUrl = '/h5/score/score-rule/find-score-rule-list';

// 获取积分明细列表
const GetPointDetailListUrl = '/h5/score/score-flow/find-score-flow-list';

// 获取积分明细详情
const GetPointDetailUrl = '/h5/score/score-flow/get';

export default {
  GetPointInformationUrl,
  GetPointDetailListUrl,
  GetPointDetailUrl,
};
