export default {
  GetRechargeConfigUrl: '/h5/mbr/mbr-card-spec-config/getCardSpecConfig',
  GetRechargeRuleDataUrl: '/h5/mbr/stored/find-stored-rule-list',
  ToRechargeUrl: '/h5/stored/pay/pre-recharge-order',
  GetRechargeResultUrl: '/h5/stored/pay/get-recharge-result',
  GetRechargeDetailListUrl: '/h5/mbr/stored/query-stored-list',
  GetRechargeDetailInfoUrl: '/h5/mbr/stored/get-stored-detail',
  GetRechargeGiftDetailUrl: '/h5/mbr/stored/get-stored-rule/gift-detail',
  getAvailableAmount: '/h5/mbr/member/get-balance',
};
