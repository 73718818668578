/**
 * 初始化state
 */

/**
 * App全局state管理
 */
const AppGlobalState = {
  responsive: { // 响应式
    data: {
      isMobile: false // 是否移动端
    }
  },
}

export default {
  AppGlobalState
}