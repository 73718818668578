/**
 * 登录请求接口Url
 */
// 入口Url
const EntryUrl = '/h5/mbr/user/entry';
// 登录Url
const LoginUrl = '/h5/mbr/user/login';
// 预登录Url
const PreLoginUrl = '/h5/mbr/user/pre-login';
// 判断用户是否登录Url
const JudgeUserLoginStatusUrl = '/h5/mbr/user/login-status';

export default {
  EntryUrl,
  LoginUrl,
  PreLoginUrl,
  JudgeUserLoginStatusUrl,
};
