import React from 'react';
import PropTypes from "prop-types";
import miss_icon from '@/common/components/404/assets/images/404-new.png';

import './notfound.less';

class NotFound extends React.Component {

  static propTypes = {
    tipTextGet: PropTypes.string,
    btnTextGet: PropTypes.string,
    btnClickGet: PropTypes.func
  };

  render() {
    const {
      tipTextGet,
      btnTextGet,
      btnClickGet
    } = this.props;
    return (
      <div className="not-found-container">
        <div className="cont-container">
          <div><img src={miss_icon} alt="" className="miss-icon" /></div>
          {
            tipTextGet && <div className="tip-style">{tipTextGet}</div>
          }

          {
            btnTextGet && <button className="short-empty" onClick={btnClickGet}>{btnTextGet}</button>
          }

        </div>
      </div>

    )
  }
}

export default NotFound;