/**
 * 公共基础请求接口Url
 */

// 发送短信验证码url
const SendCodeUrl = '/h5/mbr/user/send-mobile-code';

// 获得发送验证码接口
const GetSendCodeUrl = '/h5/mbr/user/get-verification-code';

// 上传url
const UploadUrl = '/h5/base/common/upload-file';

export default {
  SendCodeUrl,
  GetSendCodeUrl,
  UploadUrl,
};
