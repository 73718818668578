import React, { Component } from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import './index.less';

class MobileButton extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    buttonClass: PropTypes.oneOf(['longButton', 'emptyButton', 'shortButton', 'businessButton']),
    disabled: PropTypes.bool,
    icon: PropTypes.element,
    isRound: PropTypes.bool,
    customClass: PropTypes.string
  };

  static defaultProps = {
    disabled: false,
    icon: null,
    isRound: true,
    customClass: ''
  };

  render() {
    const {
      handleClick,
      text,
      buttonClass,
      disabled,
      icon,
      isRound,
      customClass
    } = this.props;

    const btnClass = classnames('button', buttonClass, {
      disabled: disabled,
      square: !isRound
    }, customClass);

    return (
      <div className="button-container">
        <div className={btnClass} onClick={handleClick}>
          <span className="button-class-span">{text}</span>
          {icon}
        </div>
      </div>
    );
  }
}

export default MobileButton;